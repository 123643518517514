import {
    BASE_URL_PREFIX,
    endpoints,
    FALLBACK_FEE_CONTENT,
    FALLBACK_HIW_CONTENT,
    TERMS_ID_NAME_MAP,
} from '../../shared/constants';
import {
    IServiceGuarantees,
    ISemHeader,
    ISpecials,
    IFeeContent,
    ISurCharges,
    IReview,
    ILegal,
    IProApplyContent,
    IBenefits,
    IInclusionsExclusions,
    IFaqContent,
    IHomepagePrices,
    IServiceHIW,
    IDynamicPage,
    IPricesTexts,
    ILegalHeaderLinks,
    IWorkingHours,
} from '../../interfaces/zesty';
import { getSKUS, matchSectionSurCharges } from '../sku';
import {
    IMarket,
    IMarketServices,
    IServiceType,
} from '../../interfaces/shared';
import { FALLBACK_FOOTER_LINKS } from '../../components/footer/constants';
import { IFooterLink } from '../../interfaces/footer';
import { getSKUSClient } from '../sku-client';
import { ISpecialOffer } from '../../interfaces/shared-props';
import * as Sentry from '@sentry/nextjs';

interface IMappingsParams {
    serviceTypeId: string;
    marketId: string;
    refType?: string;
}

interface ICustomTerms {
    name: string;
    content: string;
    content_plus?: string;
}

interface IDefaultZipCodeRegion {
    defaultZipCode: string;
    region: string;
}

export interface IServicePricing {
    sku_id: number;
    description: string;
}

export async function getGuarantees(serviceTypeId: string) {
    try {
        const guaranteeResponse = await fetch(
            `${endpoints.zesty.guarantees}?service_type_id=${serviceTypeId}`,
        );
        const guaranteeResponseData = await guaranteeResponse.json();
        if (!guaranteeResponseData.data) {
            return {} as IServiceGuarantees;
        }

        const { data } = guaranteeResponseData;
        return data as IServiceGuarantees;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getGuarantees',
            },
        });
        return {} as IServiceGuarantees;
    }
}

export async function getSpecials({
    serviceTypeId,
    refType = '1',
    sid = '',
}: {
    serviceTypeId: string;
    refType?: string;
    sid: string | undefined;
}) {
    try {
        const specialsResponse = await fetch(
            `${endpoints.zesty.specials}?service_type_id=${serviceTypeId}&refType=${refType}&sid=${sid}`,
        );
        const specialsResponseData = await specialsResponse.json();
        return specialsResponseData as ISpecials;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getSpecials',
            },
        });
        return {} as ISpecials;
    }
}

export async function getSurcharges({
    marketUUID,
    serviceTypeUUID,
    specialsPriceSku,
    hIWPriceSku,
    isCLient = false,
}: {
    marketUUID: string;
    serviceTypeUUID: string;
    specialsPriceSku: string;
    hIWPriceSku: string;
    isCLient?: boolean;
}) {
    return new Promise<ISurCharges>((resolve) => {
        const request = {
            marketIds: [marketUUID],
            serviceTypeIds: [serviceTypeUUID],
        };
        let surcharges = {
            specialsSectionSurCharge: '',
            hiwSectionSurCharge: '',
        };

        if (isCLient) {
            getSKUSClient(request)
                .then((fetchedSkus) => {
                    if (fetchedSkus.length > 0) {
                        surcharges = matchSectionSurCharges(fetchedSkus, {
                            specialsSectionSurCharge: specialsPriceSku,
                            hiwSectionSurCharge: hIWPriceSku,
                        });
                    }
                    resolve(surcharges);
                })
                .catch(() => {
                    resolve(surcharges);
                });
        } else {
            getSKUS(request)
                .then((fetchedSkus) => {
                    if (fetchedSkus.length > 0) {
                        surcharges = matchSectionSurCharges(fetchedSkus, {
                            specialsSectionSurCharge: specialsPriceSku,
                            hiwSectionSurCharge: hIWPriceSku,
                        });
                    }
                    resolve(surcharges);
                })
                .catch(() => {
                    resolve(surcharges);
                });
        }
    });
}

export async function getSemHeader({
    serviceTypeId,
    marketId,
    refType = '1',
}: IMappingsParams) {
    try {
        const response = await fetch(
            `${endpoints.zesty.semHeader}?service_type_id=${serviceTypeId}&market_id=${marketId}&refType=${refType}`,
        );
        const semHeaderResponseData = await response.json();
        const { data } = semHeaderResponseData;
        return data as ISemHeader;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getSemHeader',
            },
        });
        return {} as ISemHeader;
    }
}

export async function getCustomBookingTerms(id: string) {
    try {
        const response = await fetch(
            `${endpoints.zesty.terms}?name=${TERMS_ID_NAME_MAP[id]}`,
        );
        const terms: ICustomTerms = await response.json();
        const { content, content_plus } = terms;
        return `${content}${content_plus || ''}`;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getCustomBookingTerms',
            },
        });
        return '';
    }
}

export async function getHowItWorks(
    serviceTypeId?: string,
): Promise<IServiceHIW> {
    let url = endpoints.zesty.howItWorks;
    if (serviceTypeId) {
        url += `?service_type_id=${serviceTypeId}`;
    }

    try {
        const hiwResponse = await fetch(url);
        const hiwResponseData = await hiwResponse.json();
        const { data } = hiwResponseData;
        return data ?? FALLBACK_HIW_CONTENT;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getHowItWorks',
            },
        });
        return FALLBACK_HIW_CONTENT;
    }
}

export async function getHIWPageContent() {
    try {
        const hiwContentResponse = await fetch(
            `${endpoints.zesty.hiwPageContent}`,
        );
        const hiwContentResponseData = await hiwContentResponse.json();
        const { data } = hiwContentResponseData;
        return data;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getHIWPageContent',
            },
        });
        return {};
    }
}

export async function getFeeContent(serviceTypeId: string) {
    try {
        const feeResponse = await fetch(
            `${endpoints.zesty.fee}?service_type_id=${serviceTypeId}`,
        );
        const feeResponseData = await feeResponse.json();
        if (!feeResponseData.data) {
            return FALLBACK_FEE_CONTENT as IFeeContent;
        }

        const { data } = feeResponseData;
        return data as IFeeContent;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getFeeContent',
            },
        });
        return FALLBACK_FEE_CONTENT as IFeeContent;
    }
}

export async function getMappings({
    serviceTypeId,
    marketId,
}: IMappingsParams) {
    if (!serviceTypeId || !marketId) {
        return null;
    }

    try {
        const response = await fetch(
            `${endpoints.zesty.mappings}?service_type_id=${serviceTypeId}&market_id=${marketId}`,
        );
        const responseData = await response.json();
        if (!responseData.data) {
            return null;
        }

        const { data } = responseData;

        return {
            serviceTypeUUID: data.serviceTypeUUID as string,
            marketUUID: data.marketUUID as string,
        };
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getMappings',
            },
        });
        return null;
    }
}

export async function getServiceType(serviceTypeId: string) {
    try {
        const response = await fetch(
            `${endpoints.zesty.serviceType}?service_type_id=${serviceTypeId}`,
        );
        const responseData = await response.json();
        if (!responseData.data) {
            return null;
        }

        const { data } = responseData;

        return data as IServiceType;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getServiceType',
            },
        });
        return null;
    }
}

export async function getMarket(marketId: string) {
    try {
        const response = await fetch(
            `${endpoints.zesty.market}?market_id=${marketId}`,
        );
        const responseData = await response.json();
        if (!responseData.data) {
            return;
        }

        const { data } = responseData;

        return data as IMarket;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getMarket',
            },
        });
        return;
    }
}

export async function getReviews(serviceTypeId: string) {
    try {
        const response = await fetch(
            `${endpoints.zesty.reviews}?service_type_id=${serviceTypeId}`,
        );

        const responseData = await response.json();
        const { data } = responseData;

        return data as Array<IReview>;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getReviews',
            },
        });
        return null;
    }
}

export async function getReviewsHeadline(serviceTypeId: string) {
    try {
        const response = await fetch(
            `${endpoints.zesty.reviewsHeadline}?service_type_id=${serviceTypeId}`,
        );

        const responseData = await response.json();
        const { data } = responseData;

        return data?.[0]?.headline || '';
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getReviewsHeadline',
            },
        });
        return '';
    }
}

export async function getdefaultZipCodeRegion(superMarketId: string) {
    try {
        const response = await fetch(
            `${endpoints.zesty.defaultZipCodeRegion}?super_market_id=${superMarketId}`,
        );

        const responseData = await response.json();
        if (!responseData.data) {
            return;
        }
        const { data } = responseData;

        return data as IDefaultZipCodeRegion;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getdefaultZipCodeRegion',
            },
        });
        return;
    }
}

export async function getServicePricing(service_type_id: string) {
    try {
        const servicePricingResponse = await fetch(
            `${endpoints.zesty.servicePricing}?service_type_id=${service_type_id}`,
        );
        const servicePricingData = await servicePricingResponse.json();
        const { data } = servicePricingData;
        return data as IServicePricing[];
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getServicePricing',
            },
        });
        return [];
    }
}

export async function getLegal(slug: string) {
    try {
        const legal = await fetch(`${endpoints.zesty.legal(slug)}`);
        const data = await legal.json();
        return data as ILegal;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getLegal',
            },
        });
        return {};
    }
}

export async function getProApplyContent() {
    try {
        const response = await fetch(endpoints.zesty.proApply);
        const responseData = await response.json();
        const { data } = responseData;

        return data as IProApplyContent[];
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getProApplyContent',
            },
        });
        return [] as IProApplyContent[];
    }
}

export async function getBenefits({
    serviceTypeId,
    sid = '',
}: {
    serviceTypeId: string;
    sid: string | undefined;
}) {
    try {
        const benefitsResponse = await fetch(
            `${endpoints.zesty.benefits}?service_type_id=${serviceTypeId}&sid=${sid}`,
        );
        const resp = await benefitsResponse.json();
        if (!resp.data) {
            return { benefits: [], headline: '' };
        }

        const formattedBenefits = {
            headline: resp.data.headline,
            benefits: resp.data.benefits.split('#'),
            list_title: resp.data.list_title,
            sub_title: resp.data.sub_title,
        };
        return formattedBenefits as IBenefits;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getBenefits',
            },
        });
        return { benefits: [], headline: '' };
    }
}

export async function getInclusionsExclusions({
    serviceTypeId,
    sid = '',
}: {
    serviceTypeId: string;
    sid: string | undefined;
}) {
    try {
        const inclusionsexclusionsResponse = await fetch(
            `${endpoints.zesty.inclusionsexclusions}?service_type_id=${serviceTypeId}&sid=${sid}`,
        );
        const responseData = await inclusionsexclusionsResponse.json();
        if (!responseData.data) {
            return null;
        }

        return responseData.data as IInclusionsExclusions;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getInclusionsExclusions',
            },
        });
        return null;
    }
}

export async function getFaqContent() {
    try {
        const faq = await fetch(endpoints.zesty.faqContent);
        const data = await faq.json();
        return data as IFaqContent[];
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getFaqContent',
            },
        });
        return [];
    }
}

export async function getServices() {
    try {
        const services = await fetch(`${endpoints.zesty.services}`);
        const data = await services.json();
        return data.data as IServiceType[];
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getServices',
            },
        });
        return [];
    }
}

export async function getMarketsServices() {
    try {
        const response = await fetch(endpoints.zesty.marketsServices);
        const responseData = await response.json();
        const { data } = responseData;

        return data as Array<IMarketServices>;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getMarketsServices',
            },
        });
        return;
    }
}

export async function getPricesPageTexts() {
    try {
        const response = await fetch(endpoints.zesty.pricesTexts);
        const responseData = await response.json();
        const { data } = responseData;

        return data as IPricesTexts[];
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getPricesPageTexts',
            },
        });
        return;
    }
}

export async function getFooterLinks(): Promise<IFooterLink[]> {
    try {
        const response = await fetch(endpoints.zesty.footerLinks);
        const { data }: { data: IFooterLink[] } = await response.json();
        if (Array.isArray(data)) {
            data.sort((a, b) => a.order - b.order);

            data.forEach((a) => (a.link = `${BASE_URL_PREFIX}` + a.link));

            return data;
        }

        return FALLBACK_FOOTER_LINKS;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getFooterLinks',
            },
        });
        return FALLBACK_FOOTER_LINKS;
    }
}

export async function getHomepagePrices() {
    try {
        const response = await fetch(endpoints.zesty.homepagePrices);
        const responseData = await response.json();
        const { data } = responseData;
        return data as IHomepagePrices;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getHomepagePrices',
            },
        });
        return {} as IHomepagePrices;
    }
}

export async function getDynamicPageTexts(sid: string) {
    try {
        const dynamicPage = await fetch(
            `${endpoints.zesty.dynamicPages}?sid=${sid}`,
        );
        const responseData = await dynamicPage.json();
        if (!responseData.data) {
            return {} as IDynamicPage;
        }
        return responseData.data as IDynamicPage;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getDynamicPageTexts',
            },
        });
        return {} as IDynamicPage;
    }
}

export async function getLegalHeaderLinks(): Promise<ILegalHeaderLinks[]> {
    try {
        const linkResponse = await fetch(endpoints.zesty.legalHeaderLinks);
        const { data }: { data: ILegalHeaderLinks[] } =
            await linkResponse.json();

        if (Array.isArray(data)) {
            data.sort((a, b) => a.order - b.order);
            data.forEach((a) => {
                if (!a.link.includes('http')) {
                    a.link = `${BASE_URL_PREFIX}` + a.link;
                }
            });
            return data;
        } else return [];
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getLegalHeaderLinks',
            },
        });
        console.error(err);
        return [];
    }
}

export async function getSpecialOffer(sid: string) {
    try {
        const response = await fetch(endpoints.zesty.specialOfferDetails);
        const responseData = await response.json();
        const data = responseData.data as Array<ISpecialOffer> | undefined;
        const offer = data?.find((x) => x.sids.includes(sid)) || null;
        return offer;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getSpecialOffer',
            },
        });
        return null;
    }
}

export async function getWorkingHours(timezone: 'et' | 'default') {
    try {
        const response = await fetch(
            `${endpoints.zesty.workingHours}?timezone=${timezone}`,
        );
        const responseJson = await response.json();
        if (!responseJson.data) {
            return null;
        }
        return responseJson.data as IWorkingHours;
    } catch (err) {
        Sentry.captureException(err, {
            extra: {
                offender: 'getWorkingHours',
            },
        });
        return null;
    }
}
