import type { AppProps } from 'next/app';
import Script from 'next/script';
import {
    ScreenSizeContextProvider,
    ProgressIndicatorOverlayContextProvider,
} from '@ftdr/blueprint-components-react';
import { SemProvider } from '../state/sem.context';
import { NotificationProvider } from '../state/notification.context';
import { Notification } from '../components/notification';
import { notificationIds } from '../shared/constants';
import '../styles/main.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import getConfig from 'next/config';
import { PagesContext, PagesProvider } from '../state/pages.context';
import { useContext, useEffect } from 'react';
import CookieConsent from '../components/shared/cookie-consent';

const {
    publicRuntimeConfig: { NODE_ENV_VALUE },
} = getConfig();
const queryClient = new QueryClient();

// console.log('CI_COMMIT variables:', {
//     CI_COMMIT_SHORT_SHA: process.env.CI_COMMIT_SHORT_SHA,
//     CI_COMMIT_REF_NAME: process.env.CI_COMMIT_REF_NAME,
//     CI_COMMIT_REF_SLUG: process.env.CI_COMMIT_REF_SLUG,
// });

const tealiumScript = `
    (function(a,b,c,d){
    a='https://tags.tiqcdn.com/utag/americanhomeshield/ahs/${
        NODE_ENV_VALUE === 'production' ? 'prod' : 'dev'
    }/utag.js';
    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
    a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
    })();

    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
    window.utag_cfg_ovrd.noview = true;
`;

const appsFlyerScript = `
    //Initializing Smart Script arguments
    var oneLinkURL = "https://frontdoor.onelink.me/1GOt"
    var mediaSource = { keys: ["utm_source"], defaultValue: "website" }
    var campaign = { keys: ["utm_campaign"], defaultValue: "not%20set" }
    var channel = { keys: ["utm_medium"], defaultValue: "not%20set" }
    var googleClickIdKey = "af_sub2"
    var afSub3 = { keys: ["mskclid"], defaultValue: "not%20set" }
    var fbclid = { paramKey: "fbclid", defaultValue: "af_sub4" }
    var af_prt = { paramKey: "af_prt", keys: ["af_prt"] }
    var custom_ss_ui = { paramKey: "af_ss_ui", defaultValue: "true" }
    var custom_ss_gtm_ui = { paramKey: "af_ss_gtm_ui", defaultValue: "true" }
    //Function is embedded on the window object in a global parameter called window.AF_SMART_SCRIPT.
    //Onelink URL is generated.
    AF_SMART_SCRIPT_RESULT = window?.AF_SMART_SCRIPT && window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL: oneLinkURL,
    afParameters: {
    mediaSource: mediaSource,
    campaign: campaign,
    channel: channel,
    googleClickIdKey: googleClickIdKey,
    afSub3: afSub3,
    afCustom: [fbclid, af_prt, custom_ss_ui, custom_ss_gtm_ui],
    },
    })

    var event = new CustomEvent('af-smart-script-ready');
    window.dispatchEvent(event);
`;

function ComponentWrapper({ Component, pageProps }: AppProps) {
    const { setVariation, setIsAddVariationReady } = useContext(PagesContext);

    useEffect(() => {
        if (setVariation('') === null || setIsAddVariationReady() === null) {
            return;
        }

        // initialize Optimizely addVariation window function
        window.addVariation = (name: string) => {
            if (name) {
                console.log('addVariation', name);
                setVariation(name);
            }
        };

        setIsAddVariationReady(true);
    }, [setVariation, setIsAddVariationReady]);

    return <Component {...pageProps} />;
}

function App(appProps: AppProps) {
    return (
        <>
            <Script
                strategy="afterInteractive"
                src="https://code.jquery.com/jquery-3.5.1.slim.min.js"
                integrity="sha256-4+XzXVhsDmqanXGHaHvgh1gMQKX40OUvDEBTu8JcmNs="
                crossOrigin="anonymous"
            />
            <Script
                id="tealiumScript"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: tealiumScript,
                }}
            />
            <script
                defer
                type="text/javascript"
                src="https://onelinksmartscript.appsflyer.com/onelink-smart-script-latest.js"
            ></script>
            <Script
                id="appsFlyerScript"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: appsFlyerScript,
                }}
                defer
            />
            <script
                async
                src="https://consent.trustarc.com/notice?domain=frontdoor.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&privacypolicylink=https://www.ahs.com/privacy-policy/?pr=1"
            ></script>
            <PagesProvider>
                <QueryClientProvider client={queryClient}>
                    <NotificationProvider>
                        <ScreenSizeContextProvider desktopBreakpoint="md">
                            <ProgressIndicatorOverlayContextProvider>
                                <SemProvider>
                                    <>
                                        <ComponentWrapper {...appProps} />
                                        <Notification
                                            id={notificationIds.generalToaster}
                                        />
                                        <CookieConsent />
                                    </>
                                </SemProvider>
                            </ProgressIndicatorOverlayContextProvider>
                        </ScreenSizeContextProvider>
                    </NotificationProvider>
                </QueryClientProvider>
            </PagesProvider>
        </>
    );
}

export default App;
